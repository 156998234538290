import { AudioProvider } from '@capturi/audio'
import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  DrawerProps,
  Flex,
} from '@chakra-ui/react'
import ConversationDetails from 'components/ConversationDetails'
import React, { useState } from 'react'

import { ConversationPageView } from '../../pages/Conversations/routes'

type DrawerOptions = {
  uid: string
  initialAudioTimestamp?: number
  initialTab?: ConversationPageView
  showOverlay?: boolean
}

export type DrawerContainerProps = DrawerOptions & Omit<DrawerProps, 'children'>

const HeaderWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Flex
      alignItems="start"
      borderBottom="1px solid"
      borderColor="gray.200"
      justifyContent="space-between"
      gap={4}
      h="100%"
      p={2}
      pb={0}
      pr={1}
    >
      {children}
      <Divider orientation="vertical" h={10} borderLeftWidth="2px" />
      <DrawerCloseButton position="static" mt={1} />
    </Flex>
  )
}

export const DrawerContainer: React.FC<DrawerContainerProps> = ({
  uid,
  initialAudioTimestamp,
  initialTab,
  showOverlay = false,
  isOpen,
  onClose,
}) => {
  const [conversationUid, setConversationUid] = useState<string>(uid)
  return (
    <AudioProvider>
      <Drawer isOpen={isOpen} onClose={onClose} placement="right" size="xl">
        {showOverlay && <DrawerOverlay />}
        <DrawerContent
          // Fixes bug when scrolling with mouse wheel on second level drawer did not work
          onWheel={(e) => {
            e.stopPropagation()
          }}
          onTouchMove={(e) => {
            e.stopPropagation()
          }}
        >
          <DrawerBody overflow="hidden" p={4}>
            <ConversationDetails
              uid={conversationUid}
              initialAudioTimestamp={initialAudioTimestamp}
              initialTab={initialTab}
              separateHeader={true}
              HeaderWrapper={HeaderWrapper}
              navigateToConversation={setConversationUid}
              isSidebarView={true}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </AudioProvider>
  )
}

import Icon_AI from '@capturi/assets/images/AI_small.svg'
import {
  SearchParamsObject,
  toFilterSearchParams,
  useFilterPeriodContext,
  useFirstPhoneSegmentState,
} from '@capturi/filters'
import { downloadBlob } from '@capturi/request'
import request, { ResponseError } from '@capturi/request'
import { useToast } from '@capturi/ui-components'
import { Icon } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { useMutation } from '@tanstack/react-query'
import { useCallback } from 'react'
import {
  RepeatCallFilterSettings,
  useRepeatCallFilterSettings,
} from '../Shared/useRepeatCallFilterSettings'

type ReportStatus = 'Processing' | 'Ready' | 'Error'

export type UseReportResponse = {
  status: 'Ready' | 'Error'
  uid: string
}

export type UseTopTimeConsumersKeyTopicsResponse =
  | UseReportResponse
  | {
      status: 'Empty' // used when average duration is zero (no conversations matched filter)
    }

const AlternativeClusteringVersionQueryKey = 'alternativeClusteringVersion'

const getAlternativeClusteringVersion = () => {
  return new URLSearchParams(window.location.search).get(
    AlternativeClusteringVersionQueryKey,
  )
}

const checkStatus = async (
  reportUid: string,
  onError?: (error: Error, reportUid?: string) => void,
): Promise<UseReportResponse> => {
  const data = await request.get<{
    status: ReportStatus
    uid: string
  }>(`reports/${reportUid}`, {
    query: { 'api-version': '3.3' },
  })

  if (data.status === 'Error') {
    const error = new Error('Failed to generate a report', {
      cause: data.status,
    })
    onError?.(error, reportUid)
    throw error
  }

  if (data.status === 'Ready') {
    return {
      status: 'Ready',
      uid: data.uid,
    }
  }

  await new Promise((resolve) => setTimeout(resolve, 1000))
  return checkStatus(reportUid)
}

const queryFn = async (
  filter: SearchParamsObject,
  repeatCallSettings?: RepeatCallFilterSettings,
  onStart?: (data: {
    status: ReportStatus
    uid: string
  }) => void,
  onError?: (error: Error, reportUid?: string) => void,
) => {
  const alternativeClusteringVersion = getAlternativeClusteringVersion()
  let report: {
    status: ReportStatus
    uid: string
  }
  try {
    report = await request.post<{
      status: ReportStatus
      uid: string
    }>('reports', {
      json: { filter, repeatCallSettings },
      query: {
        'api-version': '3.3',
        ...(alternativeClusteringVersion && {
          alternativeClusteringVersion,
        }),
      },
    })
  } catch (error) {
    if (error instanceof ResponseError) {
      throw error
    }
    throw new Error('Failed to generate a report', {
      cause: error,
    })
  }
  onStart?.(report)
  return await checkStatus(report.uid, onError)
}

export const useDownloadReportMutation = () => {
  const toast = useToast()
  const segmentState = useFirstPhoneSegmentState()
  const { periodDef } = useFilterPeriodContext()
  const filter = {
    ...toFilterSearchParams({ ...segmentState?.values }, periodDef),
  }
  const repeatCallSettings = useRepeatCallFilterSettings()

  const onStart = useCallback(
    (data: {
      status: ReportStatus
      uid: string
    }) => {
      toast({
        id: `report-toast-${data.uid}-generating`,
        description: t`Generating report. Can take up to 30 seconds...`,
        variant: 'neutral',
        icon: <Icon pos="absolute" as={Icon_AI} boxSize="4" ml="2" mt="0.5" />,
        duration: 40000,
        isClosable: true,
      })
    },
    [toast],
  )

  const onError = useCallback(
    (_error: Error, reportUid?: string) => {
      toast.close(`report-toast-${reportUid}-generating`)
      toast({
        id: `report-toast-${reportUid}-error`,
        title: t`Failed to generate a report`,
        description: t`Please try again.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
    [toast],
  )

  const onSuccess = useCallback(
    (data: {
      status: ReportStatus
      uid: string
    }) => {
      toast.close(`report-toast-${data.uid}-generating`)
      toast({
        id: `report-toast-${data.uid}-ready`,
        description: t`Downloading report`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })

      downloadBlob(`reports/${data.uid}/pdf?api-version=3.3`, `${data.uid}.pdf`)
    },
    [toast],
  )

  return useMutation<
    {
      status: ReportStatus
      uid: string
    },
    ResponseError
  >({
    mutationKey: ['report'],
    mutationFn: async () => {
      return await queryFn(filter, repeatCallSettings, onStart, onError)
    },
    onError: (error) => {
      onError(error)
    },
    onSuccess,
  })
}

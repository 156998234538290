import { formatSecondsToShortDHM } from '@capturi/filters'
import { Box, HStack, Icon, Text, Tooltip, VStack } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'
import { MdPinDrop } from 'react-icons/md'

const CallHistoryOverview: React.FC<{
  previousConversationDistance: number | null
  nextConversationDistance: number | null
}> = ({ previousConversationDistance, nextConversationDistance }) => {
  const previousConversationWidth =
    previousConversationDistance && nextConversationDistance
      ? (previousConversationDistance /
          (previousConversationDistance + nextConversationDistance)) *
        100
      : 0

  return (
    <HStack align="end" justify="space-between" gap="1">
      {previousConversationDistance && (
        <HStack flexShrink="0" gap="1" align="baseline">
          <Text fontSize="lg" fontWeight="medium">
            {formatSecondsToShortDHM(previousConversationDistance)}
          </Text>
          <Text fontSize="sm" color="gray.600">
            <Trans>since prev call</Trans>
          </Text>
        </HStack>
      )}

      <HStack flexGrow="1" align="end" gap="0" pb="6px">
        <Box
          flexGrow={nextConversationDistance ? '0' : '1'}
          display={previousConversationDistance ? 'block' : 'none'}
          w={`calc(${previousConversationWidth}% - 10px)`}
          h="2px"
          backgroundRepeat="repeat-x"
          backgroundSize="6px 2px"
          backgroundImage="radial-gradient(circle, #E0E0E0 1px, transparent 1px)"
          transition="all 500ms ease-in-out"
        />
        <Tooltip label={t`Current conversation`} placement="top" hasArrow>
          <VStack h="19px" w="20px" gap="0" align="center">
            <Icon
              boxSize="20px"
              as={MdPinDrop}
              color="brand.primary"
              aria-label={t`Current conversation`}
              overflow="visible"
              css={css`
        path{
        filter: drop-shadow(0px 5px 7px rgba(0, 0, 0, 0.3))
      }
      `}
            />
          </VStack>
        </Tooltip>
        <Box
          flexGrow="1"
          display={nextConversationDistance ? 'block' : 'none'}
          w="1px"
          h="2px"
          backgroundRepeat="repeat-x"
          backgroundSize="6px 2px"
          backgroundImage="radial-gradient(circle, #E0E0E0 1px, transparent 1px)"
        />
      </HStack>

      {nextConversationDistance && (
        <HStack flexShrink="0" gap="1" align="baseline">
          <Text fontSize="lg" fontWeight="medium">
            {formatSecondsToShortDHM(nextConversationDistance)}
          </Text>
          <Text fontSize="sm" color="gray.600">
            <Trans>to next call</Trans>
          </Text>
        </HStack>
      )}
    </HStack>
  )
}

export default CallHistoryOverview

import analytics from '@capturi/analytics'
import { AudioProvider } from '@capturi/audio'
import { Button, ListSkeleton } from '@capturi/ui-components'
import { Select, SelectOption } from '@capturi/ui-select'
import {
  Box,
  Card,
  CardBody,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Flex,
  GridItem,
  Icon,
  List,
  Text,
  VStack,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { InfiniteData } from '@tanstack/react-query'
import { RapidSearch } from 'components/RapidSearch'
import React, { useCallback, useMemo, useState } from 'react'
import { MdLaunch } from 'react-icons/md'
import { useTimeout } from 'react-use'

import { KeyTopicsWordCloudType, WordCloudItem } from '@capturi/charts'
import {
  Benchmark,
  KeyTopicConversationClusterResponse,
  Topic,
} from '../../../pages/KeyTopics/hooks/types'
import { useKeyTopicConversations } from '../../../pages/KeyTopics/hooks/useKeyTopicConversations'
import { useConversationDetailsDrawer } from '../../ConversationDetailsDrawer'
import OptionalAudioPlayerActions from '../../OptionalAudioPlayerActions'
import { PlayListItem } from '../../PlayList'
import { DrawerBenchmarks } from '../Benchmarks'
import { DrawerBenchmarksSkeleton } from '../Benchmarks/DrawerBenchmark/DrawerBenchmarks'
import { AskAiCard, AskAiCardSkeleton } from './AskAiCard'
import { ConversationAskAiAnswer } from './ConversationAskAiAnswer'
import { ConversationCardBody } from './ConversationCardBody'
import { DrawerWordCloud } from './DrawerWordCloud'
import { useAskAiConversations } from './useAskAiConversations'

const dateFormat: Intl.DateTimeFormatOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
}

type KeyTopicsDrawerProps = {
  sessionUid: string // used in filter
  topicId: string // used in filter
  subTopicId?: string // used in filter

  referenceBenchmark: Benchmark // used in benchmarks for comparison
  clusterName: string // used as heading title
  topics?: Topic[] // used in filter
  onlyRepeatCalls?: boolean
  selectedWordCloudItem?: string
  initialWordCloudType?: KeyTopicsWordCloudType
  overridenPercentages?: {
    percentage: number
    percentageOfTotal?: number
  }
} & Omit<DrawerProps, 'children'>
type DrawerBodyProps = {
  initialWordCloudType?: KeyTopicsWordCloudType
  benchmark: Benchmark | undefined
  conversationData:
    | InfiniteData<KeyTopicConversationClusterResponse>
    | undefined
  wordCloud: { [key: string]: number } | undefined
  trendWordCloud: { [key: string]: number } | undefined
  totalConversationsPct: number
  conversationsPct: number
  referenceBenchmark: Benchmark
  isLoading: boolean
  fetchNextPage: () => void
  hasNextPage: boolean | undefined
  isFetchingNextPage: boolean
  askAiQuery: string
  setAskAiQuery: (value: string) => void
  selectedWordCloudItem?: string
  setSelectedWordCloudItem?: (value: string | undefined) => void
}

const keyTopicItemStyleProps = {
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
  bg: 'gray.200',
  _focus: {
    bg: 'gray.300',
    outline: 'none',
  },
  _hover: {
    bg: 'gray.200',
  },
  paddingTop: '12px',
}

type KeyTopicOptions = SelectOption & {
  amount: number
}

type ConversationItemProps = {
  conversation: KeyTopicConversationClusterResponse['conversations'][0]
  askAiQuery?: string
}

const ConversationItem: React.FC<ConversationItemProps> = ({
  conversation,
  askAiQuery,
}) => {
  const openConversationDetailsDrawer = useConversationDetailsDrawer()
  const [megaDrawerLinkVisible, setMegaDrawerLinkVisible] = useState(false)

  const { uid, dateTime, duration, keySubTopic, keyTopic, subject, userName } =
    conversation
  return (
    <GridItem py={3} key={uid}>
      <Card borderRadius="0px" boxShadow="none" border="none">
        <PlayListItem
          {...keyTopicItemStyleProps}
          context={{ conversationUid: uid }}
          timestamp={0}
          showAudioPlayer
          playOnClick={false}
          onPlay={() =>
            analytics.event('keyTopics_Conversations_PlayAudio', {
              uid,
              date: dateTime.toUTCString(),
            })
          }
          additionalActions={(props) => (
            <OptionalAudioPlayerActions
              {...props}
              conversationUid={uid}
              duration={duration}
            />
          )}
        >
          <Box
            w="full"
            onMouseEnter={() => setMegaDrawerLinkVisible(true)}
            onMouseLeave={() => setMegaDrawerLinkVisible(false)}
            onClick={(e) => {
              e.stopPropagation()
              openConversationDetailsDrawer({
                uid: uid,
                showOverlay: true,
              })
              analytics.event('key_topics_open_megadrawer')
            }}
            _hover={{
              textDecoration: 'underline',
            }}
          >
            <Flex align="start" justify="space-between">
              <VStack gap={0} alignItems="start">
                <Text
                  noOfLines={1}
                  wordBreak="break-all"
                  fontWeight="medium"
                  title={subject}
                >
                  {subject}
                </Text>
                <Text as="span" fontSize="xs">
                  {`${keyTopic} > ${keySubTopic}`}
                </Text>
              </VStack>
              <VStack fontSize="sm" gap={0} color="gray.600" align="end">
                {megaDrawerLinkVisible ? (
                  <Box>
                    <Icon
                      aria-label={t`Go to conversation`}
                      title={t`Go to conversation`}
                      boxSize="12px"
                      color="gray.600"
                      as={MdLaunch}
                      onClick={(e) => {
                        e.stopPropagation()
                        openConversationDetailsDrawer({
                          uid: uid,
                          showOverlay: true,
                        })
                        analytics.event('key_topics_open_megadrawer')
                      }}
                    />
                  </Box>
                ) : (
                  <Text
                    maxW="140px"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {userName}
                  </Text>
                )}

                <Text>{i18n.date(dateTime, dateFormat)}</Text>
              </VStack>
            </Flex>
          </Box>
        </PlayListItem>

        <Box zIndex="1" pos="relative">
          <ConversationCardBody borderRadius="4px">
            {askAiQuery ? (
              <ConversationAskAiAnswer
                conversationUid={conversation.uid}
                askAiQuery={askAiQuery}
              />
            ) : (
              <Text whiteSpace="pre-wrap">{conversation.rootCause}</Text>
            )}
          </ConversationCardBody>
        </Box>
      </Card>
    </GridItem>
  )
}

export const KeyTopicsDrawer: React.FC<KeyTopicsDrawerProps> = ({
  initialWordCloudType,
  isOpen,
  onClose,
  topicId,
  subTopicId,
  sessionUid,
  referenceBenchmark,
  onlyRepeatCalls = false,
  topics,
  clusterName,
  selectedWordCloudItem,
  overridenPercentages,
}) => {
  const [rapidSearchWords, setRapidSearchWords] = useState<string[]>([])
  const [highlightedWordCloudItem, setHighlightedWordCloudItem] = useState<
    string | undefined
  >(selectedWordCloudItem)
  const keyTopicOptions = useMemo(() => {
    return (topics ?? [])
      .filter((kt) => kt.amount !== 0)
      .map<KeyTopicOptions>((x) => ({
        value: x.name,
        label: `${x.name} (${x.amount.toString()})`,
        amount: x.amount,
      }))
  }, [topics])

  const [askAiQuery, setAskAiQuery] = useState('')
  const [selectedTopics, setSelectedTopics] = useState<KeyTopicOptions[]>([])
  const {
    data: topicsClusterData,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useKeyTopicConversations({
    sessionUid: sessionUid,
    keyTopicClusterId: topicId,
    keySubTopicClusterId: subTopicId,
    body: {
      pageSize: 100,
      keyTopics: selectedTopics.map((kt) => kt.value),
      phrases: rapidSearchWords ?? null,
      ngrams: highlightedWordCloudItem ? [highlightedWordCloudItem] : undefined,
      onlyRepeatCalls,
    },
    sleepBeforeResolving: true,
  })

  const [isReady] = useTimeout(600)
  return (
    <AudioProvider>
      <Drawer isOpen={isOpen} onClose={onClose} placement="right" size="xl">
        <DrawerOverlay />
        <DrawerContent maxW="1000px">
          <DrawerCloseButton right={2} top={3} w={8} h={8} />
          <DrawerHeader
            borderBottom="1px"
            borderBottomColor="gray.200"
            lineHeight={1.4}
            display="flex"
            flexDir="column"
            px={3}
            fontFamily="body"
            fontWeight="normal"
          >
            <Text
              fontSize="md"
              fontWeight="medium"
              textColor="gray.800"
              textTransform="uppercase"
            >
              {clusterName}
            </Text>

            <Text
              fontSize="xs"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              textColor="gray.600"
              w="100%"
            >
              {keyTopicOptions
                .slice(
                  1,
                  5,
                ) /* Skip first topic and stop at the fifth, as it's in label */
                .map((topic) => topic.value)
                .join(', ')}
              {keyTopicOptions.length > 5 && <Trans>, etc.</Trans>}
            </Text>
          </DrawerHeader>
          <DrawerBody
            overflow="hidden"
            px={6}
            display="flex"
            flexDirection="column"
            gap={4}
            pt={4}
            pb={0}
          >
            <Flex alignItems="flex-start" w="100%">
              <Box w="40%">
                <Select
                  isDisabled={false}
                  options={keyTopicOptions}
                  onChange={(e) => {
                    setSelectedTopics([...e])
                    analytics.event('key_topics_select_topics_in_drawer')
                  }}
                  isMulti={true}
                  noOptionsMessage={() => t`All key topics selected`}
                  placeholder={t`Select topics...`}
                  isSearchable={false}
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                  styles={{
                    valueContainer: (base) => ({
                      ...base,
                      minHeight: '2.5rem',
                      overflow: 'auto',
                      fontFamily: 'Rubik, sans-serif',
                    }),
                  }}
                />
              </Box>

              <Box w="15%" />

              <Box w="45%">
                <RapidSearch
                  setWords={setRapidSearchWords}
                  words={rapidSearchWords}
                  isLoading={isLoading}
                />
              </Box>
            </Flex>
            <DrawerBodyContent
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              wordCloud={topicsClusterData?.pages[0]?.wordCloud}
              trendWordCloud={topicsClusterData?.pages[0]?.trendWordCloud}
              benchmark={topicsClusterData?.pages[0]?.benchmark}
              conversationsPct={
                overridenPercentages
                  ? overridenPercentages?.percentage ?? 0
                  : topicsClusterData?.pages[0]?.percentage ?? 0
              }
              totalConversationsPct={
                overridenPercentages
                  ? overridenPercentages?.percentageOfTotal ?? 0
                  : topicsClusterData?.pages[0]?.percentageOfTotal ?? 0
              }
              referenceBenchmark={referenceBenchmark}
              conversationData={topicsClusterData ?? undefined}
              isLoading={isLoading || !isReady()}
              askAiQuery={askAiQuery}
              setAskAiQuery={setAskAiQuery}
              initialWordCloudType={initialWordCloudType}
              selectedWordCloudItem={highlightedWordCloudItem}
              setSelectedWordCloudItem={setHighlightedWordCloudItem}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </AudioProvider>
  )
}

const DrawerBodyContent: React.FC<DrawerBodyProps> = ({
  benchmark,
  conversationData,
  wordCloud,
  initialWordCloudType,
  trendWordCloud,
  totalConversationsPct,
  conversationsPct,
  referenceBenchmark,
  isLoading,
  hasNextPage: hasNextPageProp,
  isFetchingNextPage,
  fetchNextPage,
  askAiQuery,
  setAskAiQuery: setAskAiQueryProp,
  selectedWordCloudItem,
  setSelectedWordCloudItem,
}) => {
  const { setAskAiQuery, conversationsToShow, handleLoadMore, hasNextPage } =
    useAskAiConversations({
      conversationData,
      fetchNextPage,
      hasNextPageInConversationData: hasNextPageProp,
      askAiQuery,
    })

  const handleAskAiQueryChange = (value: string) => {
    setAskAiQuery(value)
    setAskAiQueryProp(value)
  }

  const conversations = useMemo(() => {
    return (
      (conversationData?.pages ?? [])
        .flatMap((page) => page.conversations)
        .slice(0, conversationsToShow) ?? []
    )
  }, [conversationData, conversationsToShow])

  const handleSelectWordCloudItem = useCallback(
    (item: WordCloudItem | undefined) => {
      setSelectedWordCloudItem?.(item?.name)
    },
    [setSelectedWordCloudItem],
  )

  return (
    <>
      {isLoading && benchmark == null ? (
        <Flex flexWrap="wrap" gap={2}>
          <DrawerBenchmarksSkeleton />
        </Flex>
      ) : (
        benchmark && (
          <Flex flexWrap="wrap" gap={2}>
            <DrawerBenchmarks
              benchmark={benchmark}
              referenceBenchmark={referenceBenchmark}
              conversationCount={
                conversationData && conversationData.pages.length > 0
                  ? conversationData.pages[0].benchmark.amount
                  : 0
              }
              conversationsPct={conversationsPct}
              totalConversationsPct={totalConversationsPct}
              showReferenceBenchmark={true}
            />
          </Flex>
        )
      )}

      <Flex flex={1} overflow="hidden" gap={4}>
        <Card
          w="55%"
          shadow="none"
          border={'1px solid'}
          borderColor="gray.200"
          mb={6}
        >
          <CardBody px={0} py={3}>
            <Box h="100%">
              {!isLoading && (
                <DrawerWordCloud
                  initialWordCloudType={initialWordCloudType}
                  wordCloud={wordCloud}
                  trendWordCloud={trendWordCloud}
                  selectedItemName={selectedWordCloudItem}
                  onSelectItem={handleSelectWordCloudItem}
                />
              )}
            </Box>
          </CardBody>
        </Card>
        <Box h="100%" w="1px" bg="gray.200" />
        <Box flex={1} overflow="auto" h="100%">
          {isLoading ? (
            <Box>
              <AskAiCardSkeleton />
            </Box>
          ) : (
            conversations.length > 0 && (
              <Box>
                <AskAiCard
                  initialAskAiQuery={askAiQuery}
                  setAskAiQuery={handleAskAiQueryChange}
                />
              </Box>
            )
          )}
          <List>
            <ListSkeleton isLoaded={!isLoading} rowCount={5}>
              {conversations.map((conversation) => {
                return (
                  <ConversationItem
                    key={conversation.uid}
                    conversation={conversation}
                    askAiQuery={askAiQuery}
                  />
                )
              })}
            </ListSkeleton>
          </List>
          {!isLoading && (
            <Flex my={4} justify="center" w="100%">
              <Button
                onClick={handleLoadMore}
                isDisabled={!hasNextPage || isFetchingNextPage}
                isLoading={isFetchingNextPage}
              >
                {!hasNextPage ? (
                  <Trans>No more conversations</Trans>
                ) : (
                  <Trans>Load more</Trans>
                )}
              </Button>
            </Flex>
          )}
        </Box>
      </Flex>
    </>
  )
}

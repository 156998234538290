import analytics from '@capturi/analytics'
import { ConversationResponseModel } from '@capturi/api-conversations'
import {
  ConversationSpeakerDistributionResponseModel,
  insightsAPI,
} from '@capturi/api-insights'
import { useAPI } from '@capturi/api-utils'
import { AudioProvider } from '@capturi/audio'
import { useFeatureFlags } from '@capturi/feature-flags'
import { ErrorBoundary } from '@capturi/react-utils'
import { User } from '@capturi/stores'
import {
  Box,
  Flex,
  Tab,
  TabList,
  TabListProps,
  TabPanel,
  TabPanelProps,
  TabPanels,
  TabProps,
  Tabs,
  usePrevious,
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useEffect, useState } from 'react'
import { useMatch, useSearchParams } from 'react-router-dom'
import { SWRConfiguration } from 'swr'

import {
  ConversationPageView,
  toConversationPageView,
} from '../../../pages/Conversations/routes'
import AudioSkeleton from '../Audio/Skeleton'
import { Hit } from '../Audio/types'
import Layout from '../Layout'
import TrackersView from '../Trackers'
import TrackersSkeleton from '../Trackers/TrackersSkeleton'
import SearchResults from '../components/SearchResults'
import TabLoadingIndicator from '../components/TabLoadingIndicator'
import WordSearchInput from '../components/WordSearchInput'
import { logTabClicked } from '../events'
import Overview from './Overview/Overview'
import { useTabsState } from './useTabsState'

const Audio = React.lazy(() => import('../Audio'))
const CoachingView = React.lazy(() => import('../Coaching'))
const HistoryView = React.lazy(() => import('../History'))
const ScoringView = React.lazy(() => import('../Scoring'))
const SentimentView = React.lazy(() => import('../Sentiment'))

type ConversationViewProps = {
  conversationUid: string
  conversation?: ConversationResponseModel
  isConversationLoading: boolean
  initialAudioTimestamp?: number
  initialTab?: ConversationPageView
  user: User
  header?: React.ReactElement
  separateHeader?: boolean
  onTabOpen?: (tab: ConversationPageView) => void
  navigateToConversation: (uid: string, tabName?: ConversationPageView) => void
}

const swrOptions: SWRConfiguration = {
  suspense: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  dedupingInterval: 10 * 60 * 1000,
}

interface ConversationTabListProps extends Omit<TabListProps, 'as'> {
  showSearchInput: boolean
  wordSearchQuery: string
  setWordSearchQuery: (value: string) => void
}

const ConversationTabList: React.FC<ConversationTabListProps> = ({
  showSearchInput,
  wordSearchQuery,
  setWordSearchQuery,
  ...restProps
}) => {
  return (
    <Flex
      width="100%"
      overflowX="auto"
      alignItems="stretch"
      sx={{
        overflow: 'auto',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
      pb="1px"
      mt="1px"
    >
      <TabList {...restProps} />

      <Flex
        w="full"
        pl="5"
        alignItems="stretch"
        outline="1px solid"
        outlineColor={'gray.200'}
      >
        {showSearchInput && (
          <WordSearchInput
            value={wordSearchQuery}
            onChange={setWordSearchQuery}
            onClear={() => setWordSearchQuery('')}
          />
        )}
      </Flex>
    </Flex>
  )
}

const ConversationTab: React.FC<
  TabProps & {
    show?: boolean
    tabName: ConversationPageView
  }
> = ({ show = true, tabName, onClick, ...props }) => {
  return (
    <Tab
      display={show ? 'block' : 'none'}
      whiteSpace="nowrap"
      onClick={(e) => {
        onClick?.(e)
        logTabClicked(tabName)
      }}
      {...props}
    />
  )
}

const ConversationTabPanel: React.FC<
  TabPanelProps & { suspenseFallback?: React.ReactElement }
> = ({ children, suspenseFallback = <TabLoadingIndicator />, ...props }) => {
  return (
    <TabPanel {...props}>
      <ErrorBoundary>
        <React.Suspense fallback={suspenseFallback}>{children}</React.Suspense>
      </ErrorBoundary>
    </TabPanel>
  )
}

const ConversationView: React.FC<ConversationViewProps> = ({
  conversationUid,
  conversation,
  isConversationLoading,
  initialAudioTimestamp: initialAudioTimestampProp,
  initialTab,
  user,
  header,
  separateHeader = false,
  onTabOpen,
  navigateToConversation,
}) => {
  const { enableSentimentFeature } = useFeatureFlags()
  const [searchParams] = useSearchParams()
  const [selectedTrackerUid, setSelectedTrackerUid] = useState<string | null>(
    null,
  )

  const initialTimestamp =
    initialAudioTimestampProp ?? (Number(searchParams.get('time')) || undefined)

  const { data: speakerDistribution } =
    useAPI<ConversationSpeakerDistributionResponseModel>(
      insightsAPI.getSpeakerDistributionStatsForConversation(conversationUid),
      swrOptions,
    )

  const isPlayable = !conversation?.deleted
  const routeMatch = useMatch('/conversations/playback/:uid/:tabName')

  const { tabs, tabIndex, getTab, selectedTab, setSelectedTab } =
    useTabsState<ConversationPageView>(
      [
        {
          value: 'overview',
          tab: t`Overview`,
        },
        {
          value: 'trackers',
          tab: t`Trackers`,
        },
        {
          value: 'sentiment',
          tab: t`Sentiment`,
          show: enableSentimentFeature,
        },
        {
          value: 'scoring',
          tab: t`Scoring`,
        },
        {
          value: 'coaching',
          tab: t`Coaching`,
        },
        {
          value: 'history',
          tab: t`Call history`,
        },
      ],
      initialTab ??
        toConversationPageView(routeMatch?.params.tabName) ??
        'overview',
    )

  const [hits, setHits] = React.useState(Array<Hit>())

  const [isSearchMode, setIsSearchMode] = React.useState(false)

  const [wordSearchQuery, setWordSearchQuery] = React.useState<string>('')

  // biome-ignore lint/correctness/useExhaustiveDependencies: legacy
  useEffect(() => {
    setWordSearchQuery('')
  }, [conversationUid])

  const handleTabsChange = useCallback(
    (index: number): void => {
      if (isConversationLoading) return
      setWordSearchQuery('')
      const tabName = getTab(index).value
      if (tabName === 'overview') {
        setSelectedTrackerUid(null)
      }
      setSelectedTab(tabName)
      onTabOpen?.(tabName)
    },
    [getTab, isConversationLoading, onTabOpen, setSelectedTab],
  )

  const lastSearchQuery = usePrevious(wordSearchQuery)
  // biome-ignore lint/correctness/useExhaustiveDependencies: legacy
  React.useEffect(() => {
    if (lastSearchQuery !== undefined && lastSearchQuery !== wordSearchQuery) {
      const isSearchMode = !isEmpty(wordSearchQuery)
      onTabOpen?.(isSearchMode ? 'search' : selectedTab)
      setIsSearchMode(isSearchMode)
    }
  }, [
    wordSearchQuery,
    conversationUid,
    lastSearchQuery,
    setIsSearchMode,
    selectedTab,
    onTabOpen,
  ])

  React.useEffect(() => {
    if (isSearchMode) analytics.event('conversationDetail_isSearchingForWords')
  }, [isSearchMode])

  const handleNavigateToTracker = useCallback(
    (currentTrackerUid: string | null): void => {
      handleTabsChange(tabs.findIndex((tab) => tab.value === 'trackers'))
      setSelectedTrackerUid(currentTrackerUid)
    },
    [handleTabsChange, tabs.findIndex],
  )

  const hitMarkTooltipContainerRef = React.useRef<HTMLDivElement>(null)
  // biome-ignore lint/correctness/useExhaustiveDependencies: legacy
  useEffect(() => {
    setSelectedTrackerUid(null)
  }, [conversationUid])
  return (
    <AudioProvider>
      <Flex direction="column" h="100%" alignItems="stretch" w="100%">
        {separateHeader && <Box flex="0 1 auto">{header}</Box>}
        <Layout.Main
          pt={separateHeader ? 4 : 0}
          ref={hitMarkTooltipContainerRef}
        >
          {!separateHeader && <Layout.Header>{header}</Layout.Header>}
          <Layout.Media>
            {isConversationLoading || conversation == null ? (
              <AudioSkeleton />
            ) : (
              <Box>
                <ErrorBoundary>
                  <React.Suspense fallback={<AudioSkeleton />}>
                    <Audio
                      agentUid={conversation.userUid}
                      salesPerson={conversation.statistics?.salesPerson}
                      salesPersonSpeakTimePercent={
                        speakerDistribution?.results?.[0]
                          .speakTimeAveragePercentSalesPerson
                      }
                      otherPerson={conversation.statistics?.otherPerson}
                      otherPersonSpeakTimePercent={
                        speakerDistribution?.results?.[0]
                          .speakTimeAveragePercentOthers
                      }
                      conversationUid={conversation.uid}
                      conversationDateTime={conversation.dateTime}
                      duration={conversation.duration}
                      initialTimestamp={initialTimestamp}
                      hits={hits}
                      hitMarkTooltipContainerRef={hitMarkTooltipContainerRef}
                      qaIsReviewed={conversation.qaIsReviewed}
                      hasAudio={conversation.hasAudio}
                      qaReviewedByUserUid={conversation.qaReviewedByUserUid}
                      qaReviewedDate={conversation.qaReviewedDate}
                      withoutConsent={conversation.withoutConsent}
                    />
                  </React.Suspense>
                </ErrorBoundary>
              </Box>
            )}
          </Layout.Media>
          <Layout.Detail>
            <Tabs
              index={isEmpty(wordSearchQuery) ? tabIndex : -1}
              onChange={handleTabsChange}
              isLazy
            >
              <ConversationTabList
                showSearchInput={isPlayable}
                wordSearchQuery={wordSearchQuery}
                setWordSearchQuery={setWordSearchQuery}
              >
                {tabs.map((o) => (
                  <ConversationTab
                    key={o.value}
                    tabName={o.value}
                    show={o.show}
                  >
                    {o.tab}
                  </ConversationTab>
                ))}
              </ConversationTabList>

              {isConversationLoading || conversation == null ? (
                <Box p={8}>
                  <TrackersSkeleton />
                </Box>
              ) : (
                <TabPanels p={0}>
                  <ConversationTabPanel
                    key="overview"
                    suspenseFallback={<TrackersSkeleton />}
                    px="1"
                  >
                    <Overview
                      conversation={conversation}
                      tabs={tabs}
                      selectedTrackerUid={selectedTrackerUid}
                      onNavigateToTracker={handleNavigateToTracker}
                      onTabsChange={handleTabsChange}
                      setHits={setHits}
                    />
                  </ConversationTabPanel>
                  <ConversationTabPanel>
                    <TrackersView
                      conversation={conversation}
                      isSearchMode={isSearchMode}
                      initialSelectedTrackerUid={selectedTrackerUid}
                      setHits={setHits}
                    />
                  </ConversationTabPanel>
                  <ConversationTabPanel key="sentiment">
                    <SentimentView
                      conversation={conversation}
                      setHits={setHits}
                    />
                  </ConversationTabPanel>
                  <ConversationTabPanel key="scoring">
                    <ScoringView
                      conversationUid={conversationUid}
                      results={conversation.scores}
                      setHits={setHits}
                    />
                  </ConversationTabPanel>
                  <ConversationTabPanel key="coaching">
                    <CoachingView
                      conversation={conversation}
                      agentName={user?.name}
                      setHits={setHits}
                    />
                  </ConversationTabPanel>
                  <ConversationTabPanel key="history">
                    <HistoryView
                      conversation={conversation}
                      setHits={setHits}
                      navigateToConversation={navigateToConversation}
                    />
                  </ConversationTabPanel>
                  {isSearchMode && (
                    <ErrorBoundary>
                      <React.Suspense>
                        <SearchResults
                          conversation={conversation}
                          wordSearchQuery={wordSearchQuery}
                          setHits={setHits}
                        />
                      </React.Suspense>
                    </ErrorBoundary>
                  )}
                </TabPanels>
              )}
            </Tabs>
          </Layout.Detail>
        </Layout.Main>
      </Flex>
    </AudioProvider>
  )
}

export default ConversationView
